import { HydratedRouter } from 'react-router/dom';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import { StrictMode, startTransition , useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

Sentry.init( {
  dsn: window.env?.SENTRY_IO_DSN,
  integrations: [
    Sentry.reactRouterV7BrowserTracingIntegration( {
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    } ),
  ],
  enabled: import.meta.env.NODE_ENV !== 'development',
  environment: import.meta.env.NODE_ENV,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', 'https://evotree.io', 'https://evotree-web.vercel.app'],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
} );

const hydrate = () => {
  startTransition( () => {
    hydrateRoot(
      document,
      <StrictMode>
        <HydratedRouter />
      </StrictMode>
    );
  } );
}

if ( typeof requestIdleCallback === 'function' ) {
  requestIdleCallback( hydrate );
} else {
  setTimeout( hydrate, 1 );
}
